import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ReactComponent as InputsIcon } from 'assets/download-inputs.svg';
import { ReactComponent as PauseIcon } from "assets/pause.svg";
import { ReactComponent as SuccessIcon } from 'assets/download-success.svg';
import { ReactComponent as FailedIcon } from 'assets/icon_downloadFailedCall.svg';
import { ReactComponent as PDFIcon } from 'assets/download-pdf.svg';

export interface QuestionMenuProps {
  showSucessful: boolean;
  showFailed: boolean;
  showRetry: boolean;
  showDownloadZip: boolean;
  showPauseBatches: boolean;
  batchProps: {
    hide: boolean;
    cancelled: boolean;
    paused: boolean;
    status: string;
  };
  onDownload: (e: boolean) => void;
  onPause: (e: boolean) => void;
  

  onDownloadInputs: () => void;
  onDownloadZip: () => void;
  retryCalls: () => void;
}

export default function ResultsMenu({ showSucessful, showFailed, onDownload, retryCalls, showRetry, onDownloadInputs, onDownloadZip, showDownloadZip, showPauseBatches, batchProps, onPause}: QuestionMenuProps) {
  return (
    <Menu as='div' className='relative inline-block text-left z-999 min-w-[21px]'>
      <div>
        <Menu.Button className='flex items-center bg-white border border-superbill-soap rounded hover:bg-superbill-anti-flash-white ease-in-out duration-150 py-2 px-2'>
          <span className='sr-only'>Open options</span>
          <svg
            width='5'
            height='19'
            viewBox='0 0 5 19'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M2.625 14.0741C3.93549 14.0741 5 15.178 5 16.537C5 17.8961 3.93549 19 2.625 19C1.31451 19 0.25 17.8961 0.25 16.537C0.25 15.178 1.31451 14.0741 2.625 14.0741ZM2.625 7.03704C3.93549 7.03704 5 8.14097 5 9.5C5 10.859 3.93549 11.963 2.625 11.963C1.31451 11.963 0.25 10.859 0.25 9.5C0.25 8.14097 1.31451 7.03704 2.625 7.03704ZM5 2.46296C5 3.82199 3.93549 4.92593 2.625 4.92593C1.31451 4.92593 0.25 3.82199 0.25 2.46296C0.25 1.10394 1.31451 0 2.625 0C3.93549 0 5 1.10394 5 2.46296Z'
              fill='#727272'
            />
          </svg>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'>
        <Menu.Items className='absolute right-0 mt-2 w-64 origin-top-right rounded bg-white shadow-lg focus:outline-none z-50'>
          <div className='font-normal border border-superbill-soap border-1 rounded z-50'>
              <Menu.Item>
                <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150' onClick={() => { onDownloadInputs() }}>
                <InputsIcon className="w-[18px]" />
                  <div className='mx-2 text-base'>Download inputs</div>
                </div>
              </Menu.Item>
            {showPauseBatches ?
              <Menu.Item>
                <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150' onClick={() => { onPause(!batchProps.paused) }}>
                  <PauseIcon className="w-[18px]" />
                  <div className='mx-2 text-base'>{batchProps.paused === true ? 'Resume ' : 'Pause '} Batch</div>
                </div>
              </Menu.Item>
              : null}
            {showSucessful ?
              <Menu.Item>
                <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150' onClick={() => { onDownload(true) }}>
                  <SuccessIcon className="w-[18px]" />
                  <div className='mx-2 text-base'>Download successful calls</div>
                </div>
              </Menu.Item>
              : null}

            {showFailed ?
              <Menu.Item>
                <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150' onClick={() => { onDownload(false) }}>
                  <FailedIcon className="w-[18px] shrink-0" />
                  <div className='mx-2 text-base'>Download incorrect / insufficient data calls</div>
                </div>
              </Menu.Item>
              : null}
            {showDownloadZip ?
              <Menu.Item>
                <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150' onClick={() => { onDownloadZip() }}>
                <PDFIcon className="w-[18px]" />
                  <div className='mx-2 text-base'>Download PDF results</div>
                </div>
              </Menu.Item>
            : null}
            {showRetry ?
              <Menu.Item>
                <div
                  className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-jacarta flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                  onClick={retryCalls}
                >
                  <div className='mx-2 text-base'>Retry calls</div>
                </div>
              </Menu.Item>
            : null}

          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}