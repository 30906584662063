import React, { useState, useEffect, useRef, useMemo } from "react";
import { ReactComponent as Spinner } from "assets/button-loading.svg";
import { DateTime } from "luxon";
import Loader from "components/Loader";
import { toast, Toaster } from "react-hot-toast";
import { getBatchCalls, getBatchData, getBatches, getRobodialers } from "apis/agent";
import BatchesTable from "./components/batches-table";
import FilterForm from "./components/filter-form";
import { QMBatchDataType } from "types/types";
import { useLocation } from "react-router-dom";


const useQuery = () => {
  const location = useLocation();
  return useMemo(() => new URLSearchParams(location.search), [location.search]);
};
export default function QMPage() {
  const query = useQuery();
  const [loading, setLoading] = useState<boolean>(true);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  
  const [batches, setBatches] = useState<QMBatchDataType[] | null>(null);
  const [batchData, setBatchData] = useState<any | null>(null);

  const fetchBatchData = async (robodialerId, batchId, force = false) => {
    if (!batchData || !batchData[batchId] || force) {
      setDataLoading(true);
      const response = await getBatchData(robodialerId, batchId);
      setDataLoading(false);
      if (response.httpCode === 200) {
        setBatchData({ ...batchData, [batchId]: response.data });
      } else {
        toast.error(
          response.httpCode +
            " Unable to load batch data! Try again:" +
            response.msg,
          {
            iconTheme: {
              primary: "#F36F82",
              secondary: "#ffffff",
            },
          }
        );
      }
    }
  };

  const fetchBatches = async () => {
    setIsSearching(true);
    const response = await getBatches({
      batchId: query.get("batchId") ?? '',
      callId: query.get("callId") ?? '',
      batchStatus: query.get("batchStatus") ?? '',
      filterBy: query.get("filterBy") ?? '',
      dateOption: query.get("dateOption") ?? '',
      dateFrom: query.get("dateFrom") ?? '',
      dateTo: query.get("dateTo") ?? '',
      robodialerId: query.get("robodialerId") ?? ''
    });
    setIsSearching(false);
    setLoading(false);
    if (response.httpCode === 200) {
      console.log(response.data.data);
      setBatches(response.data.data);
    } else {
      toast.error(
        response.httpCode +
          " Unable to load batches! Try again:" +
          response.data.error ?? response.msg,
        {
          iconTheme: {
            primary: "#F36F82",
            secondary: "#ffffff",
          },
        }
      );
    }
  };

  useEffect(() => {
    fetchBatches();
  }, []);

  useEffect(() => {
    if (batches ) {
      setLoading(false);
    }
  }, [batches, ]);

  const search = async (data) => {

    if(data.batchId && !data.robodialer.value){
      toast.error(
        "Please select Robodialer and Batch ID",
        {
          iconTheme: {
            primary: "#F36F82",
            secondary: "#ffffff",
          },
        }
      );
      return
    }
    
    if (data?.robodialer) {
      data.robodialerId = data?.robodialer?.value ?? ""
      delete data?.robodialer
    }
    setIsSearching(true);
    const response =  await getBatches(data);
    setIsSearching(false);
    setLoading(false);
    if (response.httpCode === 200) {
      console.log(response.data.data);
      setBatches(response.data.data);
    } else {
      toast.error(
        response.httpCode +
          " Unable to load batches! Try again:" +
          response.data.error ?? response.msg,
        {
          iconTheme: {
            primary: "#F36F82",
            secondary: "#ffffff",
          },
        }
      );
    }
  };
  return (
    <>
      <Toaster  toastOptions={{duration: 12000}}/>
      {loading ? (
        <>
          <div className="w-full h-full flex justify-center items-center">
            {" "}
            <Loader />{" "}
          </div>
        </>
      ) : null}
      {!loading ? (
        <div className="mx-4 mt-5 mb-20">
          <div className="flex items-center justify-start gap-x-2">
            <div className="font-jakarta text-superbill-jacarta text-xl font-bold mb-4">
              Batches
            </div>
            {
              <div className="font-jakarta text-superbill-slate-grey text-xl mb-4">
                <>{DateTime.fromJSDate(new Date()).toFormat("MMMM dd")}</>
              </div>
            }
          </div>
          {/* <div className="flex items-end justify-start gap-x-2 m-4 w-2/3">
            <div className="w-4/12">
              <label className="block text-gray-700">From:</label>
              <input
                type="date"
                ref={startDateRef}
                className="mt-1 block w-full p-2 border border-superpay-soap rounded-md"
              />
            </div>
            <div className="w-4/12">
              <label className="block text-gray-700">To:</label>
              <input
                type="date"
                ref={endDateRef}
                className="mt-1 block w-full p-2 border border-superpay-soap rounded-md"
              />
            </div>
            <div className="w-2/12">
              <label className="block text-gray-700">Status:</label>
              <select
                ref={statusRef}
                className="mt-1 block w-full p-2 border border-superpay-soap rounded-md"
              >
                <option value="">All</option>
                <option value="processing">Processing</option>
                <option value="completed">Completed</option>
              </select>
            </div>
            <div className=" w-2/12 flex items-center justify-center">
              <button
                className="mb-1 bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300"
                onClick={search}
                disabled={isSearching}
              >
                {isSearching ? <Spinner /> : <>Search</>}
              </button>
            </div>
          </div> */}
          <FilterForm search={search} defaultValues={{
            batchId: query.get("batchId") ?? '',
            callId: query.get("callId") ?? '',
            batchStatus: query.get("batchStatus") ?? '',
            filterBy: query.get("filterBy") ?? '',
            dateOption: query.get("dateOption") ?? '',
            paused: query.get("paused") ?? '',
            hidden: query.get("hidden") ?? '',
            cancelled: query.get("cancelled") ?? '',
            dateFrom: query.get("dateFrom") ?? '',
            dateTo: query.get("dateTo") ?? '',
            robodialerId: query.get("robodialerId") ?? ''
          }} />

          <div className="w-full grid grid-cols-24 border border-superpay-soap rounded bg-superbill-anti-flash-white">
            <div className="py-3 col-span-1 font-bold text-superbill-slate-grey text-sm border-b border-superpay-soap"/>
            <div className="py-3 col-span-2 font-bold text-superbill-slate-grey text-sm border-b border-superpay-soap">
              DUE
            </div>
            <div className="py-3 col-span-2 px-2 font-bold text-superbill-slate-grey text-sm border-b border-superpay-soap">
              STATUS
            </div>
            <div className="py-3 col-span-3 px-2 font-bold text-superbill-slate-grey text-sm border-b border-superpay-soap">
              ROBODIALER
            </div>
            <div className="py-3 col-span-4 px-2 font-bold text-superbill-slate-grey text-sm border-b border-superpay-soap">
              BATCH ID
            </div>
            <div className="py-3 col-span-4 px-2 font-bold text-superbill-slate-grey text-sm border-b border-superpay-soap">
              SCRIPT ID
            </div>
            <div className="py-3 col-span-2 px-2 font-bold text-superbill-slate-grey text-sm border-b border-superpay-soap text-right">
              Successful
            </div>
            <div className="py-3 col-span-2 px-2 font-bold text-superbill-slate-grey text-sm border-b border-superpay-soap text-right">
              Failed
            </div>
            <div className="py-3 col-span-2 px-2 font-bold text-superbill-slate-grey text-sm border-b border-superpay-soap text-right">
              OTHER
            </div>
            <div className="py-3 col-span-2 px-2 font-bold text-superbill-slate-grey text-sm border-b border-superpay-soap text-right">
              TOTAL
            </div>
          </div>
          <BatchesTable batches={batches}  batchData={batchData} onExpand={fetchBatchData} dataLoading={dataLoading} />
        </div>
      ) : null}
    </>
  );
}
