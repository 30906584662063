import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as SparklesIcon } from 'assets/sparkles2.svg';
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import QuestionPage from "./Question";
import { BuilderState } from "../../enums/enums";
import AvatarImg from "assets/avatar.png";
import SetupCard from "./Setup";
import QuestionsList from "./QuestionsList";
import { Questions } from "./QuestionType";
import Loader from "../../components/Loader";
import { getScript, updateScript } from "../../apis/robodialer";
import { useLocation } from "react-router-dom";
import { ReactComponent as SuperDialIcon } from 'assets/superdial-logo-icon-blue.svg';
import { ReactComponent as CheckboxIcon } from 'assets/checkbox-mode.svg';
import { ReactComponent as CustomIcon } from 'assets/custom-mode.svg';
import CalendlyModal from "./CalendlySuperdial";
import { Script } from "types/types";
import { sections } from "./pms-data";
import ChangeModeModal from "../dashboard/change-mode-modal";
import { toast } from "react-hot-toast";



const CreateScript: React.FC = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  const [loading, setLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCalendlyWidgetOpen, setIsCalendlyWidgetOpen] =
    useState<boolean>(false);
  const [isChangeModalOpen, setIsChangeModalOpen] = useState<boolean>(false);


  const [scriptData, setScriptData] = useState<Script | null>(null);
  const [externalInstructions, setExternalInstructions] = useState<string>('');
  const [currentTab, setCurrentTab] = useState<BuilderState>(BuilderState.Questions);
  const [questions, setQuestions] = useState<Array<Questions> | null>(null);
  const [selectedQuestions, setSelectedQuestions] = useState<Array<Questions>>([]);
  //const ref = useRef<HTMLFormElement>(null);
  const ref = useRef<HTMLFormElement>(null);
  const [isChecked, setIsChecked] = useState<boolean | null>(null);
  const [customMode, setCustomMode] = useState<boolean>(false);

  const toggleCheck = () => {
    setIsChangeModalOpen(true);
    setIsChecked(!isChecked);
  };

  const handleClick = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    //console.log(ref?.current);
    await ref?.current?.onSubmit();
    //setCurrentTab(BuilderState.Communication);
    //window.location.assign('./super-dial-dashboard/home')
  };
  const handleTabClick = (tab: BuilderState) => {
    setCurrentTab(tab);
  };

  const renderNav = () => {
    return (
      <nav>
        <ul className="flex items-center">
          <li className="mx-5">
            <span
              className={`font-bold text-sm font-sans text-superpay-light-text-gray cursor-pointer ${currentTab === BuilderState.Setup ? "border-b-2 border-superbill-ultramarine transition-width duration-300 pb-2" : ""
                }`}
              onClick={() => handleTabClick(BuilderState.Setup)}
            >
              Setup
            </span>
          </li>
          <li className="mx-5">
            <span
              className={`font-bold text-sm font-sans text-superpay-light-text-gray cursor-pointer ${currentTab === BuilderState.Questions ? "border-b-2 border-superbill-ultramarine transition-width duration-300 pb-2" : ""
                }`}
              onClick={() => handleTabClick(BuilderState.Questions)}
            >
              Questions
            </span>
          </li>
          <li className="mx-5">
            <span
              className={`font-bold text-sm font-sans text-superpay-light-text-gray cursor-pointer ${currentTab === BuilderState.Communication ? "border-b-2 border-superbill-ultramarine transition-width duration-300 pb-2" : ""
                }`}
              onClick={() => handleTabClick(BuilderState.Communication)}
            >
              Communication
            </span>
          </li>
          <li className="mx-5">
            <span
              className={`font-bold text-sm font-sans text-superpay-light-text-gray cursor-pointer ${currentTab === BuilderState.Result ? "border-b-2 border-superbill-ultramarine transition-width duration-300 pb-2" : ""
                }`}
              onClick={() => handleTabClick(BuilderState.Result)}
            >
              Result
            </span>
          </li>
        </ul>
      </nav>
    )
  }

  const handleUpdateScript = async (data: Array<Questions>, externalInstructions: string, pmsForm: { benefits: string, coverage: object, general: object, payments: object } | null = null) => {

    let newScriptData;

    if (!customMode && pmsForm && scriptData && scriptData?.pms) {
      const newQuestions: Questions[] = [];
      const { benefits, ...categories } = pmsForm!
      for (const category in categories) {
        newQuestions.push(
          ...Object.keys(pmsForm![category])
            .filter(prop => pmsForm![category][prop])
            .map(prop => ({
              answerType: 'text',
              hidden: true,
              checkboxId: prop,
              question: `What is the ${sections?.find(item => item.title === category)?.fields?.find(item => item.name === prop)?.label ?? prop}?`,
            }))
        );
      }
      const formQuestions = data && 0 < data?.length && data[0].question !== '' ? data : []
      const updatedQuestions = [...formQuestions, ...newQuestions];
      newScriptData = { name: scriptData?.name, questions: updatedQuestions, externalInstructions: externalInstructions, ...pmsForm }
    } else {
      newScriptData = { name: scriptData?.name, questions: data, externalInstructions: externalInstructions }
    }
    newScriptData.customMode = customMode;
    console.log('newScriptData', newScriptData);
    //return
    setIsLoading(true);
    let response = await updateScript(id, newScriptData)
    setIsLoading(false);
    if (response.httpCode === 200) {
      window.location.assign('./super-dial-dashboard/home')
    }else{
      toast.error( 'Unable to update: ' + response?.data?.error ?? '', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
          },
      });
    }

  }
  useEffect(() => {

    const fetchScripts = async () => {
      setLoading(true)
      const { data, httpCode } = await getScript(id);
      if (httpCode !== 200) {
        //console.log(httpCode);
        return;
      }
      //console.log('###', data);
      //console.log('### questions', data.questions);


      setScriptData(data)
      //setScriptName(data?.name)

      if (data?.questions && 0 < data?.questions?.length) {
        //if( (data?.questions?.length === 1 && data?.questions[0].question !== '')){
        setQuestions(data?.questions!.filter((e) => !e.hidden) as Array<Questions>);
        setExternalInstructions(data?.externalInstructions ?? '')
        //}else{
        /* if(!data.pms){
          setQuestions([{
            question: '',
            answerType: { value: '', label: '' },
          }])
        } */
        //}
      }

    }
    fetchScripts()
  }, []);
  

  useEffect(() => {
    if (scriptData !== null) {
      setIsChecked(!!scriptData?.customMode)
      setCustomMode(!!scriptData?.customMode)
      setLoading(false)
    }
  }, [scriptData]);

  

  const renderTabs = () => {
    return (
      <>
        <header className="flex items-center justify-between px-8 bg-white h-[70px]">
          <div className="flex items-center">
            <div onClick={() => {
              window.location.assign('./super-dial-dashboard/home')
            }} className="text-superbill-indigo text-xl font-jakarta cursor-pointer flex items-end">
              <SuperDialIcon className="w-[30px] mr-2" />
              {/* SUPER<strong>DIAL</strong> */}
            </div>
            <h1 className="ml-4 text-md font-semubold font-jakarta font-bold text-jacarta">{scriptData?.name}</h1>
            {scriptData?.pms ?
              <div className='flex px-2 py-1 items-center bg-superbill-lavender rounded-full ml-2'>
                <SparklesIcon />
                <div className='ml-1 text-superbill-indigo text-xs font-jakarta'>PMS Integration</div>
              </div>
              : null}
          </div>
          {/* {renderNav()} */}
          <div className="flex items-center">
            {/* <div
          onClick={() => setIsCalendlyWidgetOpen(true)}
          id="calendly-widget"
          className="cursor-pointer rounded-full hover:bg-superbill-lavender-dark flex flex-row text-sm py-2 px-4 w-fit h-fit bg-superbill-lavender-medium  border border-superbill-soap font-jakarta font-semibold ease-in-out duration-300 mr-4"
        >
          <div className="text-superbill-indigo">Contact Support</div>
        </div> */}
            {scriptData?.pms ?
              <div className="w-fit rounded-full flex items-center justify-between transition duration-500 ease-in-out mr-4 cursor-pointer border border-superbill-soap p-1">
                <div
                  className={`text-sm font-jakarta font-semibold transition duration-500 ease-in-out px-2 py-1 rounded-full flex items-center ${!customMode ? ' bg-superbill-lavender text-superbill-indigo fill-superbill-indigo' : ' text-superpay-light-text-gray fill-superpay-light-text-gray'}`}
                  onClick={toggleCheck}
                >
                  <CheckboxIcon className={`mr-1 ${!customMode ? 'fill-superbill-indigo' : ' fill-superpay-light-text-gray'}`} />
                  Checkbox Mode
                </div>
                <div
                  className={`text-sm font-jakarta font-semibold transition duration-500 ease-in-out px-2 py-1 rounded-full flex items-center ${!customMode ? ' text-superpay-light-text-gray' : 'bg-superbill-lavender text-superbill-indigo '}`}
                  onClick={toggleCheck}
                >
                  <CustomIcon className={`mr-1 ${customMode ? 'fill-superbill-indigo' : ' fill-superpay-light-text-gray'}`}/>
                  Custom Mode
                </div>
              </div>
              : null}
            <button
              className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 mr-4'
              onClick={() => {
                window.location.assign('./super-dial-dashboard/home')
              }}
            >
              Cancel
            </button>
            <button
              className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
              onClick={handleClick}
              disabled={isLoading || scriptData?.processing}
            >
              {isLoading ? <Spinner /> : <>Save</>}
            </button>
          </div>
        </header>
        {scriptData?.processing ?
        <div className="p-3 -mt-[1px] bg-superbill-lavender-medium border-y border-superbill-soap">
          <div className="text-center text-sm text-superbill-jacarta font-sans"><strong>Viewing read-only.</strong> You have calls in progress for this script. You can make changes once your current calls are completed.</div>
        </div>
        : null}
      </>
    );
  };


  const renderContent = () => {
    switch (currentTab) {
      case BuilderState.Questions:
        return <QuestionPage onSuccess={handleUpdateScript} questions={questions!} setQuestions={setQuestions} ref={ref} scriptData={scriptData} showPmsForm={!customMode} externalInstructions={externalInstructions} setExternalInstructions={setExternalInstructions}/>;
      case BuilderState.Setup:
        return <SetupCard />;
      case BuilderState.Communication:
        return (
          <div className="flex flex-wrap">
            <div className="w-full md:w-3/5 p-4">
              <QuestionsList questions={questions!} setQuestions={setSelectedQuestions} />
            </div>
            <div className="w-full md:w-2/5 p-4">
              Test
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {loading ? (
        <div>
          {renderTabs()}
          <div className='w-full h-full flex justify-center items-center'>
            {' '}
            <Loader />{' '}
          </div>
        </div>
      ) : null}
      {!loading ? (
        <div>
          {renderTabs()}
          <div className="tab-content">{renderContent()}</div>
        </div>
      ) : null}
      <div
        id="root-for-calendly-purposes"
        className="font-sans flex flex-col gap-x-4 mt-4 mx-4 md:flex-row md:mt-10 md:mx-16 z-10"
      >
        <CalendlyModal
          isOpen={isCalendlyWidgetOpen}
          setIsOpen={setIsCalendlyWidgetOpen}
        />
        <ChangeModeModal
          customMode={!!isChecked}
          onConfirm={() => {
            setCustomMode(!customMode)
            setIsChangeModalOpen(false)
          }}
          onCancel={() => {
            setIsChangeModalOpen(false)
          }}
          isOpen={isChangeModalOpen}
          setIsOpen={setIsChangeModalOpen}
        />

      </div>
    </>
  );
};

export default CreateScript;